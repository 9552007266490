/**
 *
 * Ripspot Template Page
 *
 */

import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import RipspotMemorial from 'components/RipspotMemorial';

import Spinner from 'components/Spinner';

import { getPublicRipspotById } from 'utils/api/ripspots';
import { MAP_SEARCH_ROUTE } from 'utils/routes';
import { hydrateRipspot, setViewSingleRipspot } from 'actions/ripspots';

import SEO from 'components/seo';

import { navigate } from 'gatsby';
import {
  // toastSuccess,
  toastUpdateSuccess,
  // toastError,
  toastUpdateError,
  // toastWarn,
  toastInfo,
} from 'utils/toastify';

/**
 *  RipspotTemplate
 *
 * @param {*} { ripspotId }
 * @returns
 */
const RipspotFallback = ({ params, dispatch }) => {
  const [ripspot, setRipspot] = useState({});

  const id = params.id;
  useEffect(() => {
    let toastId;
    // fetch ripspot data
    if (id) {
      (async () => {
        try {
          toastId = toastInfo('Loading ripspot...');
          const res = await getPublicRipspotById(id);
          // eslint-disable-next-line no-console
          toastUpdateSuccess(toastId, 'Success! Ripspot Loaded.');
          setRipspot(res);
        } catch (error) {
          toastUpdateError(toastId, error.message || error.reason);
          // navigate('/ripspot-not-found');
        }
      })();
    }
  }, []);

  const onViewRipspotClick = () => {
    // 1. hydrate ripspot (puts data in state for pins and edit info)
    dispatch(hydrateRipspot(ripspot));
    dispatch(setViewSingleRipspot(true));
    // 2. navigate to map
    // set bounding view box around all pins

    navigate(MAP_SEARCH_ROUTE, {
      state: {
        shouldBoundPins: true,
        viewRipspot: true,
      },
    });
  };

  const hasData = Object.keys(ripspot).length > 0;

  return (
    <>
      {hasData ? (
        <>
          <SEO
            title={`${ripspot.name} ${ripspot.birthDate.year ||
              'N/A'} - ${ripspot.deathDate.year || 'N/A'}`}
            image={
              ripspot.photoGallery.length > 0
                ? ripspot.photoGallery[0].url
                : null
            }
            description={`In celebration of ${
              ripspot.name
            } and the life they lived. ${ripspot.birthDate.year ||
              'N/A'} - ${ripspot.deathDate.year || 'N/A'}`}
            uri={`/ripspot/${ripspot.id}`}
          />
          <Container>
            <RipspotMemorial
              ripspot={ripspot}
              onViewRipspotClick={onViewRipspotClick}
            />
          </Container>
        </>
      ) : (
        <Spinner loading={hasData} cover={true} size={50} />
      )}
    </>
  );
};

RipspotFallback.propTypes = {};

export default connect()(memo(RipspotFallback));

const Container = styled.div`
  ${({ theme }) => theme.styles.grow};
  color: ${({ theme }) => theme.colors.Primary};
`;
